class Bridge {
  // Send messages to the native app
  static postMessage(name, data = {}) {
    // iOS
    window.webkit?.messageHandlers?.nativeApp?.postMessage({ name, ...data });

    // Android
    window.nativeApp?.postMessage(JSON.stringify({ name, ...data }));
  }
}

// Expose this on the window object so TurboNative can interact with it
window.TurboNativeBridge = Bridge;
export default Bridge;
